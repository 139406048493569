export type WherebyParameters = {
  // Apply default embedded UI
  embed?: boolean;

  // Participant joins the meeting with camera turned off
  video?: 'off';

  // Participant joins the meeting with microphone turned off
  audio?: 'off';

  // Show/hide the screen share button
  screenShare?: 'on' | 'off';

  // Show/hide the recording button
  recording?: 'on' | 'off';

  // Show/hide the chat button
  chat?: 'on' | 'off';

  // Hide the people button
  people?: 'on' | 'off';

  // Show/hide the leave button
  leaveButton?: 'on' | 'off';

  // Set display name of participant
  displayName?: string;

  // Hide the meeting background
  background?: 'on' | 'off';

  // Set the meeting UI language
  lang?: 'en' | 'fr' | 'es' | 'nb' | 'pt' | 'jp';

  // Float the self view to the bottom right
  floatSelf?: boolean;

  iframeSource?: string;
};

export const whereByIframeSrcBuilder = (
  wherebyUrl: string,
  parameters?: WherebyParameters,
): string => {
  if (!parameters) {
    return wherebyUrl;
  }

  const url = new URL(wherebyUrl);

  if (parameters.embed) {
    url.searchParams.append('embed', '');
  }

  if (parameters.iframeSource) {
    url.searchParams.append('iframeSource', parameters.iframeSource);
  }

  if (parameters.video) {
    url.searchParams.append('video', parameters.video);
  }

  if (parameters.audio) {
    url.searchParams.append('audio', parameters.audio);
  }

  if (parameters.screenShare) {
    url.searchParams.append('screenshare', parameters.screenShare);
  }

  if (parameters.recording) {
    url.searchParams.append('recording', parameters.recording);
  }

  if (parameters.chat) {
    url.searchParams.append('chat', parameters.chat);
  }

  if (parameters.people) {
    url.searchParams.append('people', parameters.people);
  }

  if (parameters.leaveButton) {
    url.searchParams.append('leaveButton', parameters.leaveButton);
  }

  if (parameters.displayName) {
    url.searchParams.append('displayName', encodeURIComponent(parameters.displayName));
  }

  if (parameters.background) {
    url.searchParams.append('background', parameters.background);
  }

  if (parameters.lang) {
    url.searchParams.append('lang', parameters.lang);
  }

  if (parameters.floatSelf) {
    url.searchParams.append('floatSelf', '');
  }

  return url.href;
};
