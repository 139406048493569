<template>
  <div class="video-chat">
    <v-dialog
      persistent
      v-model="dialog.videoChat"
      min-width="600"
      width="80%"
      :scrollable="true"
      :content-class="$vuetify.breakpoint.mobile ? '' : 'v-dialog--overlay'"
      :fullscreen="$vuetify.breakpoint.mobile"
      @click:outside="openDialog('confirmation')"
      data-test-id="video-chat-dialog"
    >
      <v-card class="video-chat">
        <v-toolbar dark color="primary" class="video-chat__toolbar">
          <v-spacer />

          <v-btn icon dark @click="openDialog('confirmation')" data-test-id="close-btn">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <iframe
          v-if="user && videoChat"
          style="width: 100%; min-height: 50vh; border: none"
          class="room__iframe video-chat__frame"
          :src="videoChatUrl"
          allow="camera; microphone; fullscreen; speaker; display-capture"
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.confirmation" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ $t('showroom.confirmationDialog.title') }}
        </v-card-title>

        <v-card-text>{{ $t('showroom.confirmationDialog.content') }}</v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1" text @click="confirmClose" data-test-id="confirm-btn">
            {{ $t('globals.yes') }}
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="closeDialog('confirmation')"
            data-test-id="cancel-btn"
          >
            {{ $t('globals.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'VideoChat',
  props: {
    user: {
      type: Object,
      default: null,
    },
    videoChat: {
      type: Boolean,
      default: false,
    },
    videoChatUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: {
        videoChat: false,
        confirmation: false,
      },
    };
  },
  methods: {
    openDialog(type) {
      this.dialog[type] = true;
    },
    closeDialog(type) {
      this.dialog[type] = false;
    },
    confirmClose() {
      this.closeDialog('confirmation');
      this.$emit('hide-video');
    },
  },
  mounted() {
    this.dialog.videoChat = this.videoChat;
  },
  watch: {
    videoChat(value) {
      this.dialog.videoChat = value;
    },
  },
};
</script>
