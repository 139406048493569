<template>
  <v-navigation-drawer class="user-info pa-4" v-model="isMenuOpen" app left bottom permanent>
    <div class="d-flex justify-end ma-1">
      <v-icon color="primaryFont" @click="$emit('close-menu')" data-test-id="close-btn">
        mdi-close
      </v-icon>
    </div>

    <div class="user-info__header d-flex">
      <relation
        :key="userId"
        :relation="user"
        :isContact="isContact"
        :showCalendar="false"
        :showChat="false"
        :loggedUserId="loggedInUserId"
        @go-to-profile="goToPublicProfile"
        @add-connection="addConnection"
        @remove-connection="removeConnection"
        @chat="openChat"
        @booking="booking"
      />
    </div>

    <div class="user-info__content">
      <div class="content__actions d-flex flex-wrap mb-4" v-if="!isUserLogged">
        <v-btn color="primary" depressed class="flex mb-2 mr-3" @click.native="openChat">
          <v-icon color="primaryBackground" class="mr-3">mdi-message-text-outline</v-icon>
          <span>{{ $t('exhibitor.message') }} </span>
        </v-btn>

        <v-btn
          v-if="hasWherebyUrl"
          class="flex mb-2"
          :class="{ disabled: user.status === 'offline' }"
          color="primary"
          depressed
          @click="showVideoChat"
          data-test-id="video-chat-btn"
        >
          <v-icon color="primaryBackground" class="mr-3">mdi-message-video</v-icon>
          <span>{{ $t('exhibitor.videoCall') }} </span>
        </v-btn>

        <v-btn
          class="flex mb-2"
          v-if="getUserHasAppointment(userId)"
          color="primary"
          depressed
          @click="booking"
          data-test-id="schedule-btn"
        >
          <v-icon color="primaryBackground" class="mr-3">mdi-calendar-month-outline</v-icon>
          <span>{{ $t('exhibitor.schedule') }} </span>
        </v-btn>

        <v-card class="actions__documents mt-3" v-if="hasDocuments">
          <v-toolbar dense>
            <v-toolbar-title class="documents__title">
              {{ $t('exhibitor.documents') }}
            </v-toolbar-title>
          </v-toolbar>

          <v-card-actions class="documents__items d-flex justify-start flex-wrap">
            <div
              class="d-flex flex-column align-center justify-center py-3"
              v-for="document in user.documents"
              :key="document.id"
            >
              <v-btn
                class="items__button"
                :href="document.url"
                target="_blank"
                color="primary"
                icon
                plain
                download
              >
                <v-icon>mdi-file</v-icon>
              </v-btn>
              <span class="items__name">{{ document.name }}</span>
            </div>
          </v-card-actions>
        </v-card>
      </div>

      <span class="content__description">
        <div class="black--text ql-editor" v-html="user.about"></div>
      </span>
    </div>

    <video-chat
      :user="user"
      :video-chat="videoChat"
      :video-chat-url="videoChatUrl"
      @hide-video="hideVideoChat"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Relation from '@/components/relation/Relation.vue';
import VideoChat from '@/components/video-chat/VideoChat.vue';

import { toUserBooking } from '@/navigation';
import { whereByIframeSrcBuilder } from '@/helpers/whereby/whereby.helper';
import { CLICK_EXHIBITOR, EXHIBITOR_VIDEOCHAT, getStatSocket } from '@/socket/stat-namespace';

import {
  RELATIONS_MODULE,
  GET_RELATIONS,
  ADD_TO_CONTACTS,
  REMOVE_FROM_CONTACTS,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import { APPOINTMENT_MODULE } from '@/stores/umanize-app/actions/appointment/appointment.action';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  components: {
    Relation,
    VideoChat,
  },
  name: 'UserInfo',
  props: {
    user: {
      type: Object,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    menuOpen: {
      type: Boolean,
      default: false,
    },
    wherebyUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMenuOpen: true,
      videoChat: false,
      videoChatUrl: null,
    };
  },
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(RELATIONS_MODULE, ['isConnected']),
    ...mapGetters(APPOINTMENT_MODULE, ['getUserHasAppointment']),
    userId() {
      return this.user?.id || null;
    },
    loggedInUserId() {
      return this.loggedInUser?.id || null;
    },
    isUserLogged() {
      return this.userId === this.loggedInUserId;
    },
    isContact() {
      return this.isConnected(this.user.id);
    },
    hasWherebyUrl() {
      return !!this.wherebyUrl;
    },
    hasDocuments() {
      return !!this.user.documents?.length;
    },
  },
  methods: {
    ...mapActions(RELATIONS_MODULE, [GET_RELATIONS, ADD_TO_CONTACTS, REMOVE_FROM_CONTACTS]),
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    openChat() {
      this[OPEN_CHAT]({ userIds: [this.user.id] });
    },
    booking() {
      toUserBooking(this.$router)({
        eventId: this.eventId,
        userId: this.userId,
      });
    },
    addConnection() {
      this[ADD_TO_CONTACTS](this.user);
    },
    removeConnection() {
      this[REMOVE_FROM_CONTACTS](this.user);
    },
    goToPublicProfile() {
      if (this.userId) {
        this.$router.push({
          name: 'EventPublicProfile',
          params: { userId: this.userId, eventId: this.eventId },
        });
      }
    },
    showVideoChat() {
      const url = whereByIframeSrcBuilder(this.wherebyUrl, {
        embed: true,
        background: 'on',
        chat: 'on',
        people: 'off',
        screenShare: 'on',
        recording: 'on',
        displayName: `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`,
      });

      this.videoChatUrl = url;
      this.videoChat = true;
      this.emitClickEvent(EXHIBITOR_VIDEOCHAT);
    },
    hideVideoChat() {
      this.videoChat = false;
      this.videoChatUrl = null;
    },
    emitClickEvent(type) {
      getStatSocket().emit(CLICK_EXHIBITOR, this.eventId, this.user.id, type);
    },
  },
  async mounted() {
    await this[GET_RELATIONS]();
    this.isMenuOpen = this.menuOpen;
  },
  watch: {
    menuOpen(value) {
      this.isMenuOpen = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

$header-height: rem(57px);
$header-height-mobile: rem(46px);

.user-info {
  width: 100vw !important;
  height: calc(100% - #{$header-height-mobile});
  bottom: 0;
  margin: $header-height-mobile auto auto auto;

  & .ql-editor {
    ::v-deep img {
      max-width: 100%;
    }
  }

  @include breakpoint(medium) {
    margin: $header-height auto auto auto;
  }
}

.actions {
  &__documents {
    width: 100%;
    margin: 0 5px;
    box-shadow: 0px 3px 6px #00000029 !important;
  }
}

.documents {
  &__title {
    margin: 0 auto;
    font-size: $default-font-size !important;
    font-weight: $bold;

    &:before {
      font-family: 'Material Design Icons';
      content: '\F0224';
      font-size: 30px;
      font-weight: $regular;
      color: var(--v-primary-base);
      position: absolute;
      left: 10px;
      top: 0;
    }
  }

  &__items {
    height: 7rem;
    overflow-y: scroll;
  }
}

.items {
  &__button {
    width: 3rem;
    margin-bottom: 0.3rem;
  }

  &__name {
    width: 6rem;
    text-align: center;
    font-size: $small-font-size;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@include breakpoint(medium) {
  .user-info {
    width: 60vw !important;
    height: calc(100% - #{$header-height}) !important;
    top: auto !important;

    &__header {
      margin-top: 15vh;
    }
  }
}
</style>
