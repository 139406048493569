<template>
  <div
    class="d-flex pa-4 pt-2"
    :class="{ clickable: userClickable }"
    @click="goToProfile('card')"
    data-test-id="relation"
  >
    <div class="mt-3">
      <user-avatar class="mr-2" :avatar="relation.avatar" :size="avatarSize" :no-border="true" />
    </div>

    <div>
      <template v-if="relation.smartMatchingScore">
        <h1>{{ relation.smartMatchingScore }} %</h1>
        <h4>{{ relation.firstName }} {{ relation.lastName }}</h4>
      </template>

      <template v-else>
        <h3
          @click="goToProfile('title')"
          :class="['mt-3', { clickable: !userClickable }]"
          data-test-id="name"
        >
          {{ relation.firstName }} {{ relation.lastName }}
        </h3>
      </template>

      <div class="d-flex" v-if="!isUserLogged">
        <v-btn
          v-if="!isContact"
          data-test-id="add-connection"
          @click.native="$emit('add-connection', relation)"
          icon
          color="primary"
        >
          <v-icon dense>mdi-account-plus-outline</v-icon>
        </v-btn>

        <v-btn
          v-else
          data-test-id="remove-connection"
          @click.native="$emit('remove-connection', relation)"
          icon
          color="primary"
        >
          <v-icon dense>mdi-account-minus-outline</v-icon>
        </v-btn>

        <v-btn
          v-if="showChat"
          icon
          color="primary"
          data-test-id="chat"
          @click.native="$emit('chat', relation)"
        >
          <v-icon dense>mdi-chat-outline</v-icon>
        </v-btn>

        <v-btn
          icon
          color="primary"
          data-test-id="chat"
          v-if="showCalendar"
          @click.native="$emit('booking', relation)"
        >
          <v-icon dense>mdi-calendar</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';

export default {
  name: 'Relation',
  props: {
    relation: {
      type: Object,
      required: true,
    },
    isContact: {
      type: Boolean,
      default: false,
    },
    showCalendar: {
      type: Boolean,
      default: false,
    },
    showChat: {
      type: Boolean,
      default: true,
    },
    loggedUserId: {
      type: String,
      required: true,
    },
    userClickable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UserAvatar,
  },
  computed: {
    avatarSize() {
      return '5rem';
    },
    isUserLogged() {
      return this.relation.id === this.loggedUserId;
    },
  },
  methods: {
    goToProfile(from) {
      if (this.userClickable && from === 'card') {
        this.$emit('go-to-profile', this.relation);
      } else if (!this.userClickable && from === 'title') {
        this.$emit('go-to-profile', this.relation.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/core/variables';

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.text {
  font-size: $small-font-size;
}
</style>
